import theme from '@/style';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {View} from 'react-native';
import {useInnerStyle} from '../invitation.style.hooks';
import {errorLog, goTo, setDataForSettled, toAgentApply} from '@/utils';
import NormalNav from './normal-nav';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import InvitationInfo from './invitation-info';
import InvitationBouns, {InvitationBounsProps} from './invitation-bouns';
import {useRoute} from '@react-navigation/native';
import {getUserList, getUserTotal} from '../invitation.service';
import {UserListItem, UserTotal} from '../invitation.type';
import {IUserInfo} from '@/services/global.service';
import {useShare} from '@/common-pages/hooks/share.hooks';
import InvitationRecordModal, {
  InvitationModalShowOption,
  InvitationRecordModalRef,
} from '../invitation-record-modal';
import {RuleIcon} from '../svg/rule-icon';
import './invitation.css';

const Invitation = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const {whiteSpaceStyle} = useInnerStyle();
  const {t} = useTranslation();
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [userList, setUserList] = useState<UserListItem[]>();
  const [userTotal, setUserTotal] = useState<UserTotal>();
  const [login, setLogin] = useState(false);
  const recordModalRef = useRef<InvitationRecordModalRef>(null);
  const {code, refreshCode, doShare, initShare} = useShare();
  const route = useRoute();
  /** Invite的时候不要返回按钮 */
  const [hideBack] = useState(route.name === 'Invite');
  const initUser = useCallback(() => {
    try {
      globalStore
        .asyncGetItem('user')
        .then(res => res && setUserInfo(JSON.parse(res) as IUserInfo));
    } catch (e) {
      errorLog('error', e);
    }
    if (login) {
      globalStore.globalLoading.next(true);
      Promise.allSettled([getUserList(), getUserTotal(), initShare()])
        .then(([list, total]) => {
          setDataForSettled(setUserList, list);
          if (list.status === 'fulfilled') {
            globalStore.asyncSetItem(
              'invitationBonusList',
              JSON.stringify(list.value),
            );
          }
          if (total.status === 'fulfilled' && total.value != null) {
            setUserTotal(total.value);
          }
        })
        .finally(() => globalStore.globalLoading.next(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);
  // useFocusEffect(initUser);
  useEffect(() => {
    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);
  useEffect(() => {
    const tokenSub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      if (!token) {
        window.location.href = '/login';
        return;
      }
    });
    return () => {
      tokenSub.unsubscribe();
    };
  }, []);
  const completeCount = useMemo(() => {
    const len = userList?.filter(
      item => item.completedNumber >= item.inviteNumber,
    ).length;
    return len || 0;
  }, [userList]);
  const bounsList = useMemo<InvitationBounsProps[]>(() => {
    return (
      userList?.map(item => ({
        inviteTaskUserId: item.inviteTaskUserId,
        bouns: item.level,
        reward: item.bonusAmount,
        needInvites: item.inviteNumber,
        needRecharge: item.depositAmount,
        rechargedCount: item.completedNumber,
        invitedCount: item.invitedNumber,
      })) || []
    );
  }, [userList]);

  const toRules = () => {
    goTo('InvitationRule');
  };
  const toRecord = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('InvitationRecord');
  };
  const handleGoComplete = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    doShare({
      tip: t('invitation.home.copy-text'),
    });
  };

  const handleShare = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    doShare({
      tip: t('invitation.home.copy-text'),
    });
  };

  const handleUserRecordModal = (options?: InvitationModalShowOption) => {
    recordModalRef.current?.show(options);
  };

  const handleRefresh = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    globalStore.globalLoading.next(true);
    refreshCode().finally(() => globalStore.globalLoading.next(false));
  };
  if (!login) {
    return <></>;
  }
  return (
    <div className="invitation-container">
      {!hideBack && <NormalNav title={t('home.tab.proxyhome')} />}
      {/* <NormalNav title={t('home.tab.proxyhome')} /> */}
      <div
        className="invitation-content"
        style={{
          height: hideBack ? '100%' : 'calc(100vh - 2.75rem)',
        }}>
        <div style={{position: 'relative'}}>
          <div className="content-top" />
          <div className="content-mid" />
          <div className="content-bottom" />
        </div>
        <div className="real-content-top">
          <div className="code-wrap">
            <div className="invitation-rule" onClick={toRules}>
              <RuleIcon />
              <span className="invitation-rule-text">
                {t('home.tab.rules')}
              </span>
            </div>
            <div className="code-bg">
              <div className="code-action-wrap">
                <div className="code-text">{code || '--------'}</div>
                <div className="code-actions">
                  <div onClick={() => handleShare()} className="copy-action" />
                  <div onClick={handleRefresh} className="refresh-action" />
                </div>
              </div>
            </div>
          </div>
          <div className="invitation-tabs">
            {[
              t('home.tab.agentBenefits'),
              t('home.tab.agentExtraCommission'),
            ].map((item, index) => (
              <div
                key={index}
                onClick={() => setActiveTabIndex(index)}
                className={
                  index === activeTabIndex
                    ? 'invitation-tab-active'
                    : 'invitation-tab-default'
                }>
                {item}
              </div>
            ))}
          </div>
          {activeTabIndex === 0 ? (
            <div className="invitation-tab1-content">
              <div className="tab1-top" />
              <div className="tab2-top" />
              <div className="tab3-top" />
              <div className="tab4-top" />
            </div>
          ) : (
            <div
              style={{
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0) 2%, #20b7d3 10%, #20B7D3)',
              }}>
              <div
                style={{
                  paddingTop: '1rem',
                  paddingRight: '1rem',
                  paddingLeft: '1rem',
                }}>
                <InvitationInfo
                  onRule={toRules}
                  onRecord={toRecord}
                  onAgency={toAgentApply}
                  userTotal={userTotal}
                  agent={userInfo?.isAgent === 1}
                  completeCount={completeCount}
                />
              </div>
              <View
                style={[
                  theme.padding.lrl,
                  theme.flex.col,
                  whiteSpaceStyle.bottom,
                ]}>
                {bounsList.map((bonus, index) => (
                  <InvitationBouns
                    key={index}
                    {...bonus}
                    onGoComplete={handleGoComplete}
                    onShowRecord={handleUserRecordModal}
                  />
                ))}
              </View>
              <InvitationRecordModal
                ref={recordModalRef}
                onShare={handleShare}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invitation;
